.pageEl {
    border: 1px solid red;
  }
  
  // Reduce bg image size in edit mode
.edit_mode .layoutContainer.image-bg .image-bg img {
  max-width: 300px;
}


